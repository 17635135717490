<template>
  <div class="d-flex flex-column fill-height">
    <div>
      <v-simple-table height="300" dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                <div class="d-flex justify-space-between">
                  <span style="align-self: center">Fecha y Hora</span>
                  <v-btn
                    v-if="itemsTable && itemsTable.length"
                    color="success"
                    @click="toExcel"
                    icon
                  >
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn>
                </div>
              </th>
              <th class="text-left">Dispositivo</th>
              <th class="text-left">Tipo de alerta</th>
              <th class="text-left">Conductor</th>
              <th class="text-left">Estatus</th>
              <th class="text-left">Razonamiento</th>
              <!-- <th class="text-left">Tiempo de salida</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in itemsTable"
              :key="item.id"
            >
              <td>{{ item.serverTime | datetime }}</td>
              <td>
                  {{ devicesSelected[item.deviceId].name }}
              </td>
              <td>{{  eventsType[item.type] ? eventsType[item.type].name : '' }}</td>
              <td>{{  item.attributes.conductor || '' }}</td>
              <td>{{  item.attributes.status }}</td>
              <td>{{  item.attributes.razonamiento }}</td>
              <!-- <td></td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'underscore';
import Dialog from '@/components/dialog';
import Form from '@/components/form';
import ApiService from '@/util/api.service';
import arrow from '@/assets/map/arrow.svg';
import { periods } from '@/util';
import ExportToExcel from '@/util/json_to_excel.service';

const eventsType = {
  geofenceEnter: {
    id: 'geofenceEnter',
    name: 'Entró a geocerca',
  },
  geofenceExit: {
    id: 'geofenceExit',
    name: 'Salió de geocerca',
  }
};

export default {
  name: 'EventsReport',
  props: {
    devices: Array,
    groups: Array,
  },
  data() {
    return {
      events: [],
      itemsTable: [],
      devicesSelected: {},
      eventsType,
      filtro: {
        deviceId: [],
        type: '',
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59',
      },
      filtersForm: {
        deviceId: {
          label: 'Unidades',
          type: Array,
          options: this.devices,
          multiple: true,
          optional: true,
        },
        'deviceId.$': {
          type: String,
          blackbox: true,
        },
        groupId: {
          label: 'Grupos',
          type: Array,
          options: this.groups,
          multiple: true,
          optional: true,
        },
        'groupId.$': {
          type: String,
          blackbox: true,
        },
        type: {
          label: 'Tipo',
          type: String,
          multiple: true,
          options: _.toArray(eventsType),
        },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom');
            fields.from.setVisibility(val === 'custom');
            fields.fromHour.setVisibility(val === 'custom');
            fields.to_separator.setVisibility(val === 'custom');
            fields.to.setVisibility(val === 'custom');
            fields.toHour.setVisibility(val === 'custom');
          },
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true,
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false,
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true,
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true,
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false,
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    devicesSelectedArray() {
      return _.toArray(this.devicesSelected);
    },
  },
  mounted() {
  },
  methods: {
    async load() {
      window.VMA.loading(true);
      const data = {
        deviceId: this.filtro.deviceId,
        groupId: this.filtro.groupId,
        type: this.filtro.type,
        ...this.period,
      };
      try {
        this.events = await ApiService({
          url: '/reports/events',
          method: 'get',
          params: data,
        });
        const devicesSelected = {};
        const devicesId = _.unique(this.events.map((event) => event.deviceId));
        devicesId.forEach((deviceId) => {
          const device = _.findWhere(this.devices, { id: deviceId });
          if (device) {
            devicesSelected[deviceId] = { ...device };
          }
        });
        this.devicesSelected = devicesSelected;
        this.itemsTable = this.events
      } catch (e) {
        console.error(e);
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' });
      } finally {
        window.VMA.loading(false);
      }
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro,
      });
      const dialog = new Dialog({
        title: 'Filtrar alertamientos',
        actions: [
          {
            help: 'Filtrar',
            icon: 'mdi-filter',
            color: 'secondary',
            action: async () => {
              if (form.hasErrors()) {
                return;
              }
              this.selected = null;
              const item = form.getItem();
              this.filtro = item;
              if (item.periodo !== 'custom') {
                this.period = form.fields.periodo.getOption().getPeriod();
              } else {
                this.period = form.fields.periodo.getOption().getPeriod({
                  ..._.pick(item, 'from', 'to', 'fromHour', 'toHour'),
                });
              }
              this.load();
              dialog.close();
            },
          },
        ],
      });
      dialog.open();
      dialog.append(form);
    },
    toExcel() {
      ExportToExcel(
        'Eventos de unidades '
          + moment(this.period.from).format('DD-MM-YYYY HH mm')
          + ' a '
          + moment(this.period.to).format('DD-MM-YYYY HH mm'),
        this.itemsTable.map((item) => ({
          'Fecha y Hora': moment(item.serverTime).format('DD-MM-YYYY HH:mm'),
          Unidad: this.devicesSelected[item.deviceId].name,
          Tipo: this.eventsType[item.type] ? this.eventsType[item.type].name : '',
          Conductor: item.attributes.conductor,
          Estatus: item.attributes.status,
          Razonamiento: item.attributes.razonamiento
        }))
      );
    },
  },
};
</script>

<style lang="scss">
.map {
  height: 100%;
  width: 100%;
}
</style>
